<template>
    <div v-if="!loading">
        <zw-table ref="table"
                  tableName="footers"
                  columnsPrefix="footers.column."
                  :items="myProvider"
                  :fields="fields"
                  :filter="filter"
                  :selected="selected"
                  :sticky="false"
                  sticky-header="100%"
                  :actions-list="getActions()"
        >
            <template #cell(footer)="row">
                <div v-html="row.item.footer"></div>
            </template>
            <template #cell(is_default)="row">
                {{ row.item.is_default ? $t('common.button.yes') : $t('common.button.no') }}
            </template>
            <template #cell(locale)="row">
                {{ getCountry(row.item.locale) }}
            </template>
            <template #cell(doc_types)="row">
                {{ row.item.doc_types ? row.item.doc_types.join(', ') : '' }}
            </template>

            <template #cell(actions)="row">
                <div v-html="row.item.footer"></div>
            </template>
            <template #cell(actions)="row">
                <b-dropdown class="mb-1"
                            left
                            variant="primary"
                            size="sm"
                >
                    <template #button-content>
                        {{ $t('common.label.actions') }}
                    </template>

                    <b-dropdown-item
                        @click="$root.$children[0].openModal('footer-modal', {id: row.item.id},shown,{width: '800px'})">
                        <font-awesome-icon class="mr-2" icon="edit"/>
                        {{ $t('common.title.edit') }}
                    </b-dropdown-item>
                    <b-dropdown-item
                        @click="deleteFooter(row.item.id)">
                        <font-awesome-icon class="mr-2" icon="trash"/>
                        {{ $t('common.title.delete') }}
                    </b-dropdown-item>
                </b-dropdown>
            </template>
        </zw-table>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'
import commonSave from '@/bundles/erika_common_bundle/mixins/common-save'
import commonTable from '@/bundles/erika_common_bundle/mixins/common-table'

export default {
    name: 'SettingsFooters',
    mixins: [commonTable, commonSave],
    data() {
        return {
            loading: true,
            footers: [],
            saveFilters: true,
        }
    },
    methods: {
        ...mapGetters('Footers', ['getFootersTable']),
        ...mapGetters('CommonData', ['getLocales']),
        shown() {
            this.loading = false
            this.$root.afterSettingsUpdate()
        },
        myProvider(ctx) {
            return this.commonProviderFetch(ctx, 'Footers/fetchFooters', 'getFootersTable');
        },
        deleteFooter(id) {
            this.$removeConfirm('Footers/deleteFooter', id, this.shown)
        },
        getCountry(local) {
            let result = local;
            Object.entries(this.getLocales()).forEach(function (value, index) {
                if (value[1].code == local) {
                    result = value[1].name;
                }
            });
            return result;
        },
        getActions() {
            let actions = [
                {
                    title: this.$t('common.button.create'),
                    icon: 'plus',
                    click: () => {
                        this.$root.$children[0].openModal('footer-modal', {id: 0}, this.shown, {width: '800px'})
                    }
                }
            ]
            return actions
        }
    },
    mounted() {
        this.shown()
    }
}
</script>